import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../../auth/services/auth.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    const authData = localStorage.getItem('access');
    if (authData) {
      const parsedAuthData = JSON.parse(authData);
      const token = parsedAuthData.token;
      const decodedToken: any = this.authService['decodeToken'](token);
      const isValidToken = this.authService['isTokenValid'](decodedToken);

      if (isValidToken) {
        return true;
      }
    }

    this.router.navigate(['/auth/login']);
    return false;
  }
}
