<div class="layout-container">
  <!-- Sidebar -->
  <aside class="sidebar">
    <!-- Perfil -->
    <div class="profile-section">
      <i class="pi pi-user profile-image"></i>
      <h2>Nombre Completo.</h2>
      <span class="role-badge">ADMINISTRADOR</span>
    </div>
  
    <!-- Barra de búsqueda -->
    <div class="search-bar">
      <i class="pi pi-search search-icon"></i>
      <input type="text" placeholder="Buscar" />
    </div>
  
    <!-- Navegación -->
    <nav>
      <ul>
        <li *ngFor="let option of filteredMenuOptions">
          <a [routerLink]="option.route">
            <i [class]="option.icon + ' icon'"></i>
            <span>{{ option.label }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </aside>
  

  <!-- Main content -->
  <div class="main-content">
    <!-- Navbar -->
    <header class="navbar">
      <div class="navbar-right">
        <!-- Fecha -->
        <span class="current-date">Julio 26 del 2024</span>
        <!-- Iconos -->
        <button class="icon-button">
          <i class="pi pi-envelope"></i>
        </button>
        <button class="icon-button"> 
          <i class="pi pi-bell"></i>
        </button>
        <button class="icon-button">
          <i class="pi pi-cog"></i>
        </button>
        <button class="icon-button" (click)="logout()">
          <i class="pi pi-sign-out"></i>
        </button>
      </div>
    </header>

    <!-- Área de contenido dinámico -->
    <section>
      <router-outlet></router-outlet>
    </section>
  </div>
</div>
