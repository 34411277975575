import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '../services/employee.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  permissions: string[] = []; // Almacenará los permisos del usuario
  currentDate: string = '';
  userData: any = null;
  fullname: string = '';
  employeeData: any = null;
  notificationCount: number = 0;
  notifications: any[] = [];
  isMenuCollapsed: boolean = false;

  @ViewChild('op') op!: OverlayPanel;

  menuOptions = [
    {
      label: 'Inicio',
      icon: 'pi pi-home',
      route: '/dashboard/home',
      permission: 'VIEW_INICIO_ADMIN',
    },
    {
      label: 'Boletas',
      icon: 'pi pi-file',
      route: '/dashboard/payment-slips',
      permission: 'VIEW_BOLETAS_ADMIN',
    },
    {
      label: 'Solicitudes',
      icon: 'pi pi-clock',
      route: '/dashboard/solicitude',
      permission: 'VIEW_SOLICITUDES_ADMIN',
    },
    {
      label: 'Ticket y Vales',
      icon: 'pi pi-ticket',
      route: '/dashboard/tickets',
      permission: 'VIEW_TICKETS_ADMIN',
    },
    {
      label: 'Vacaciones',
      icon: 'pi pi-sun',
      route: '/dashboard/vacation',
      permission: 'VIEW_INICIO_ADMIN',
    },
    {
      label: 'Empleados',
      icon: 'pi pi-user',
      route: '/dashboard/employee',
      permission: 'VIEW_INICIO_ADMIN',
    },
  ];

  filteredMenuOptions: any[] = [];

  constructor(
    private router: Router,
    private employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
      this.getName();
    }

    this.loadPermissions();
    this.filterMenuOptions();

    const date = new Date();

    // Array con los meses en español
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    // Construir la fecha en el formato deseado
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Formato: "Diciembre 18 del 2024"
    this.currentDate = `${month} ${day} del ${year}`;

    this.getAllEmployeesToRenewContract();
  }

  getAllEmployeesToRenewContract(): void {
    this.employeeService.getEmployeesToRenewContract().subscribe({
      next: (data: any[]) => {
        if (data && data.length > 0) {
          console.log('data de renovacion', data);

          this.processContractNotifications(data);
        } else {
          this.clearContractNotifications();
        }
      },
      error: (err) => {
        console.error('Error al obtener el listado:', err);
        this.clearContractNotifications();
      },
    });
  }

  clearContractNotifications(): void {
    this.notifications = this.notifications.filter(
      (n) => !n.isContractNotification
    );
    this.notificationCount = this.notifications.length;
  }

  processContractNotifications(employees: any[]): void {
    // Crear notificaciones basadas en los empleados
    if (employees.length === 1) {
      const employee = employees[0];
      this.notifications = [
        {
          title: 'Contratos por vencer',
          message: `El contrato de ${employee.employeeName} está por vencer el día ${employee.contractEndDate}`,
          isContractNotification: true,
        },
      ];
    } else {
      this.notifications = [
        {
          title: 'Contratos por vencer',
          message: `Tienes ${employees.length} empleados con contratos por vencer. <a href="/dashboard/employee/list" class="see-more-link">Ver más</a>`,
          isContractNotification: true,
          employees: employees,
        },
      ];
    }

    this.notificationCount = this.notifications.length;
  }

  loadPermissions(): void {
    const storedAccess = localStorage.getItem('access');
    if (storedAccess) {
      const parsedAccess = JSON.parse(storedAccess);
      this.permissions = parsedAccess.permissions || [];
    }
  }

  filterMenuOptions(): void {
    this.filteredMenuOptions = this.menuOptions.filter((option) =>
      this.permissions.includes(option.permission)
    );
  }

  getName(): void {
    this.employeeService
      .getAuthenticatedUser(this.userData.employeeId)
      .subscribe(
        (employee) => {
          if (employee) {
            this.employeeData = employee;
            this.fullname =
              employee.names.split(' ')[0] +
              ' ' +
              employee.lastNames.split(' ')[0];
          } else {
            console.log('No se encontró el empleado');
          }
        },
        (error) => {
          console.error('Error al obtener el empleado:', error);
        }
      );
  }

  toggleNotification(event: Event, button: HTMLElement) {
    this.op.toggle(event, button);

    setTimeout(() => {
      const panelElement = document.querySelector(
        '.p-overlaypanel'
      ) as HTMLElement;
      const button = document.querySelector('.pi-bell')?.parentElement;

      if (panelElement && button) {
        const navbar = document.querySelector('.navbar') as HTMLElement;
        const buttonRect = button.getBoundingClientRect();

        const navbarRect = navbar?.getBoundingClientRect();
        let offsetLeft = navbarRect
          ? buttonRect.left - navbarRect.left
          : buttonRect.left;

        if (this.isMenuCollapsed) {
          offsetLeft -= 180;
        }

        panelElement.style.left = `${offsetLeft - 15}px`;
        panelElement.style.top = `${buttonRect.bottom - 5}px`;
        panelElement.style.transform = `translateX(-50%)`;
      }
    }, 0);
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  updateNotifications(): void {
    this.notifications = [];

    this.notificationCount = this.notifications.length;
  }

  toggleMenu(): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }
}
