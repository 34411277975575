import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  permissions: string[] = []; // Almacenará los permisos del usuario

  menuOptions = [
    {
      label: 'Inicio',
      icon: 'pi pi-home',
      route: '/dashboard/home',
      permission: 'VIEW_INICIO_ADMIN',
    },
    {
      label: 'Boletas',
      icon: 'pi pi-file',
      route: '/dashboard/payment-slips',
      permission: 'VIEW_BOLETAS_ADMIN',
    },
    {
      label: 'Solicitudes',
      icon: 'pi pi-users',
      route: '/dashboard/solicitudes',
      permission: 'VIEW_SOLICITUDES_ADMIN',
    },
    {
      label: 'Ticket y Vales',
      icon: 'pi pi-ticket',
      route: '/dashboard/tickets',
      permission: 'VIEW_TICKETS_ADMIN',
    },
  ];

  filteredMenuOptions: any[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.loadPermissions();
    this.filterMenuOptions();
  }

  loadPermissions(): void {
    const storedAccess = localStorage.getItem('access');
    if (storedAccess) {
      const parsedAccess = JSON.parse(storedAccess);
      this.permissions = parsedAccess.permissions || [];
    }
  }

  filterMenuOptions(): void {
    this.filteredMenuOptions = this.menuOptions.filter(option =>
      this.permissions.includes(option.permission)
    );
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
