import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './auth/services/auth.service';
import { MessageService } from 'primeng/api';
import { routes } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { ToastModule } from 'primeng/toast';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  declarations: [
    AppComponent,  // Declara el componente raíz de la aplicación
   ],
   imports: [
    BrowserModule,
    RouterModule.forRoot(routes), // Configuración de rutas globales
    HttpClientModule, // Servicios HTTP globales
    BrowserAnimationsModule, // Animaciones para toda la app
    ToastModule, // Importa módulo para las notificaciones
    AuthModule, // Importa el módulo de autenticación
    LayoutModule
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthService,MessageService],
  bootstrap: [AppComponent],  // Componente raíz que se va a cargar al iniciar la aplicación
})
export class AppModule {}